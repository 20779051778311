import * as React from 'react';
import type { Location as BaseLocation } from '@bondvet/types/locations';
import type { FileNode, LocationWithCoordinates } from 'lib/types';
import {
    type AppointmentType,
    Species,
    type PetDetailsInput,
    type BookingStep,
    type BookingStepEvent,
    BOOKING_STEPS,
} from '@bondvet/types/booking';
import { Dispatch, SetStateAction } from 'react';
import { Moment } from 'lib/types';

export type { PetDetailsInput, BookingStepEvent, BookingStep };

export { BOOKING_STEPS };

export type Location = Omit<
    LocationWithCoordinates,
    'phoneNumber' | 'intlPhoneNumber'
>;

export interface BookingStepProps {
    className: string;
    active: boolean;
    path?: string;
    reschedule?: boolean;
    cityId?: string;
    setCityId?: React.Dispatch<React.SetStateAction<string>> | undefined;
    locationId?: string;
    locations?: readonly LocationWithCoordinates[];
}

export type DayData = {
    date: string;
    label: string;
    hasSlots: boolean;
    isToday: boolean;
    isPast: boolean;
    isSelected: boolean;
    isBeforeOpening: boolean;
};

export type PartOfDay = 'morning' | 'afternoon' | 'evening';

export type Slot = {
    label: string;
    time: string;
};

export type GroupedSlots = {
    partOfDay: PartOfDay;
    slots: ReadonlyArray<Slot>;
};

export type AppointmentInput = {
    firstName: string;
    lastName: string;
    pet?: string;
    animalId?: string | null;
    phone: string;
    reminderMinutes: number;
    sendReminder: boolean;
    sendSurvey: boolean;
    species: Species;
    /**
     * _id of AppointmentReason
     */
    symptoms: string;
    email: string;
    time: string;
    locationId: string;
    promoCode?: string | null;
    notes?: string | null;
    creditCardSetupIntentId?: string | null;
    clientId?: string | null;
    selectedProviderId?: string | null;
    petDetails?: PetDetailsInput;
};

export type AppointmentData = {
    _id: string;
    firstName?: string | null;
    lastName?: string | null;
    clientId: string;
    email: string;
    date: string;
    timezone: string;
    pet: string;
    notes?: string;
    hasProvider?: boolean | null;
    phoneNumber?: string | null;
    minCancellingMs?: number;
    minReschedulingMs?: number;
    minVeryLateCancellingMs?: number;
    minVeryLateReschedulingMs?: number;
    freeReschedulingAllowed?: boolean;
    freeVeryLateReschedulingAllowed?: boolean;
    freeVeryLateCancellingAllowed?: boolean;
    freeCancellingAllowed?: boolean;
    location: Pick<
        BaseLocation,
        | '_id'
        | 'name'
        | 'address'
        | 'webLink'
        | 'phoneNumber'
        | 'intlPhoneNumber'
    > & {
        dropOffsOnly?: boolean;
        bookingSettings?: {
            allowIndividualProviderBookings: boolean;
        };
        fullAddress?: {
            city?: string;
            state?: string;
            country?: string;
            streetAddress?: string;
        };
    };
    appointmentType?: Pick<AppointmentType, '_id' | 'name'> | null;
};

export type Provider =
    | { id: string; name: string } // provider selected
    | { state: 'any'; id?: string } // provider set to be "any"
    | null; // provider not selected

export type PreselectBookingData = {
    locationId?: string;
    date?: string;
    time?: string;
    provider?: Provider | null;
};

export type ProvidersResult = {
    id: string;
    name: string;
    isRelief: boolean;
    clinicTeamMemberId: string;
    image?: FileNode;
};

export type SlotWithProvider = {
    dateTime: string;
    provider: ProvidersResult;
};

export interface TimeSlotsResult {
    timezone: string;
    slotsWithProviders: ReadonlyArray<SlotWithProvider>;
}

export interface TimeSlotsVariables {
    locationId: string;
    from: string;
    to: string;
    firstAvailable: boolean;
}

export interface TimeSlotsQuery {
    timeSlotsWithProviders: TimeSlotsResult;
}

export type TimeControl = {
    next: () => void;
    nextYear: () => void;
    previous: () => void;
    previousYear: () => void;
    hasPrevious: boolean;
    hasPreviousYear: boolean;
    hasNext: boolean;
    hasNextYear: boolean;
};

export type TimeSlotsControls = {
    week: Moment;
    month: Moment;
    year: Moment;
    monthControl: TimeControl;
    weekControl: TimeControl;
    locationId?: string;
    allSlots: ReadonlyArray<Slot> | null;
    weekDays: ReadonlyArray<DayData | null>;
    monthDays: ReadonlyArray<DayData | null>;
    slots: ReadonlyArray<GroupedSlots> | null;
    ungroupedSlots: ReadonlyArray<Slot> | null;
    timezone: string;
    isMobile: boolean;
    isNextWeek: boolean;
    isLoadingDates: boolean;
    isLoadingSlots: boolean;
    provider: Provider;
    setProvider: Dispatch<SetStateAction<Provider>>;
    weekProviders: ReadonlyArray<ProvidersResult> | null;
    monthProviders: ReadonlyArray<ProvidersResult> | null;
    selectDate: (newDate: string) => void;
    selectSlot: (newSlot: string) => void;
    selectedDate: string;
    selectedSlot?: string;
    todayYYYYMMDD: string;
    selectedDateString: string;
    selectedDateStringWithDay: string;
    selectedDateMMMMDYYYY: string;
    daysOfTheWeek: ReadonlyArray<string>;
};

export const BOOING_FLOW_SPLIT_TEST_NAME = 'BF_SPLIT';

export enum BookingFlowSplitTestValue {
    'hideSignalmentFields' = 'BF_SPLIT_hideSignalmentFields',
    'showSignalmentFields' = 'BF_SPLIT_showSignalmentFields',
}

export const BOOKING_FLOW_SPLIT_TEST_VALUES = [
    BookingFlowSplitTestValue.hideSignalmentFields,
    BookingFlowSplitTestValue.showSignalmentFields,
];
